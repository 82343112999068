import Layout from "../components/layout"
import {graphql} from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import Content, { HTMLContent } from '../components/Content'
import BodyClassName from 'react-body-classname';
import '../layouts/trip.styl'
import LeftArrow from  '../img/left-arrow.svg'
import RightArrow from  '../img/right-arrow.svg'
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import ScrollAnimation from 'react-animate-on-scroll';


export const TripTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  coverquote,
  date,
  blend,
  timeToRead,
  helmet,
  thumb,
  path,
  prev,
  next,
  templateKey,
}) => {
  const TripContent = contentComponent || Content;
  const hasNext = next && (next.frontmatter.templateKey === templateKey);
  const hasPrev = prev && (prev.frontmatter.templateKey === templateKey);
  return (
    <Layout>
      <PageTransition>
        <BodyClassName className="trip">
          <div>
            <Helmet title={title+` • John Dilworth Art & Design`} />
            <div className="coverImage" style={{ background: `linear-gradient(180deg, rgba(0,0,0,.4) 0%, rgba(0, 0, 0, 0.2) 29.83%, rgba(0, 0, 0, 0) 100%), url(`+thumb+`)`, backgroundSize: `cover,cover`, backgroundPosition: `center,center`, backgroundBlendMode: blend +`,screen`}}>
            <ScrollAnimation animateIn="fadeInUp2">
              <header className="articleHeader">
                <h1>{title}</h1>
                <p className="no-margin">{description}</p>
                <p className="no-margin"><small>{date}</small></p>
              </header>
            </ScrollAnimation>
            <p>
            {hasPrev &&
              prev &&
              <Link to={prev.fields.slug} className="nextButton">
                <img className="nextArrow" src={RightArrow} alt="next"/>
              </Link>
            }

            {hasNext &&
              next &&
              <Link to={next.fields.slug} className="prevButton">
                <img className="prevArrow" src={LeftArrow} alt="previous"/>
              </Link>
            }
            </p>
          </div>
          <article className="article">
            <div className="content">
            <blockquote className="intro">{coverquote}</blockquote>
            <TripContent content={content} className='article-content'/>
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            </div>
            </article>
          </div>
        </BodyClassName>
      </PageTransition>
    </Layout>
  )
}

TripTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet),
  prev: PropTypes.object,
  next: PropTypes.object,
  templateKey: PropTypes.string,
}

const Trip = ({data, pageContext}) => {
  const { markdownRemark: trip } = data;
  const { next, prev } = pageContext;

  return (
    <TripTemplate
      content={trip.html}
      contentComponent={HTMLContent}
      description={trip.frontmatter.description}
      tags={trip.frontmatter.tags}
      title={trip.frontmatter.title}
      date={trip.frontmatter.date}
      coverquote={trip.frontmatter.coverquote}
      image={trip.frontmatter.image}
      timeToRead={trip.timeToRead}
      blend={trip.frontmatter.blend}
      thumb={trip.frontmatter.thumb.childImageSharp.fluid.src}
      templateKey={trip.frontmatter.templateKey}
      path={trip.frontmatter.path}
      prev= {prev}
      next= {next}
    />
  )
}

Trip.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object,
  })
}

export default Trip

export const pageQuery = graphql`
  query TripByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      timeToRead
      frontmatter {
        templateKey
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        coverquote
        path
        blend
        thumb{
          childImageSharp{
            fluid(maxWidth: 1200){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
